
export default {
	addNew: {
		min: "最低金額",
		failed: "已退出",
		success: "已結算",
		waiting: "進行中",
		submit: "加入",
		day: "天",
		time: "時長",
		foot: "理財",
		tab0: "理財項目",
		tab1: "理財訂單",
	},
	common: {
		recharge_price: "币种价值",
		recharge_number: "充值数量",
		winner_money: "盈利",
		trade_number: "订单编号",
		balance2: "账户资金",
		balance1: "现货资产价值",
		withdraw_real: "到账数量",
		withdraw_money: "提现数量",
		withdraw_tips: "ULink交易所提现手续费为0.2%",
		withdraw_fee: "手续费",
		notice1: "請先陞級為商家帳戶，詳情聯系客服",
		trc_address2: "Trc地址",
		trc_address: "Trc地址",
		footer_1: "首頁",
		footer_2: "大廳",
		footer_3: "訂單",
		footer_4: "我的",
		verify_waiting: "待审核",
		verify_success: "成功",
		verify_failed: "失败",
		app_name: "ULink",
		currency_unit: "$",
		confirm: "确认",
		cancel: "取消",
		save: "保存",
		no_data: "无数据",
		copy: "複製",
		copy_success: "复制成功！",
		copy_error: "复制失败",
		tip: "提示",
		to: "向",
		search: "搜索",
		language: "语言选择",
		freezeMessage: "请联系您的主管",
		completeOrder: "您确定要完成此订单吗？",
		logout: "你确定要注销吗？",
		logout_success: "退出成功！",
		logout_filed: "退出失败！",
		currency: "货币",
		deposit: "存款",
		withdraw: "退出",
		invite: "邀请",
		balance: "余额",
		deposit_successful: "存款成功",
		withdraw_successful: "成功退出",
		deposit_fail: "存款失败",
		details: "详细信息",
		add: "添加",
		remind: "提醒",
		success: "成功",
		description: "说明",
		submit: "提交",
		no_record: "无记录",
		countryCode: "国家代码",
		withdraw0: "申请",
		withdraw1: "待定",
		withdraw2: "成功",
		withdraw3: "失败",
		orderNow: "立即订购",
		noBalance: "账户可用余额不足 ！",
		needTo: "账户可用余额不足！您需要充值 ",
		all: "全部",
		nodata: "暫無數據",
	},
	addnew: {
		notice: "您好，歡迎來到ULink平台！ 在線客服服務時間為上午9點至24点！",
	},
	account: {
		download: "APP下載",
		forget: "忘記密碼？",
		now_login: "立即登錄",
		no_account: "沒有賬號?",
		now_register: "立即註冊",
		welcome: "歡迎",
		email_register: "郵箱註冊",
		phone_register: "手機註冊",
		email_forget: "郵箱找回",
		phone_forget: "手機找回",
		cilck_obtain: "點擊獲取",
		back_login: "返回登錄",
	},
	hall: {
		sell: "出售",
		get_amount: "獲得金額",
		merchant_upgrade: "商家升級",
		ibuy: "我要買",
		isell: "我要賣",
		selling_order: "合賣訂單",
		welfare_orders: "福利訂單",
		merchant: "商家	",
		unit_price: "單價",
		number: "數量",
		surplus: "剩餘",
		limit: "限額",
		go_sell: "去出售",
		name: "姓名",
		balance: "餘額",
		profit: "獲利",
		bank_account: "銀行賬號",
		now_price: "當前價格",
		optimal_price: "最优價格",
		confirm_buy: "確定買入",
		confirm_sell: "確定賣出",
	},
	home: {
		notice: "您好，歡迎來到ULink平台！ 在線客服服務時間為上午9點至24点！",
		home_nav1: "新手教程",
		home_nav2: "邀請註冊",
		home_nav3: "團隊列表",
		home_nav4: "系統消息",
		quick_trans: "快捷交易",
		one_click: "一鍵購買",
		ctc_trans: "C2C交易",
		buy_sell: "買賣",
		online: "在線用戶",
		total_trans: "24H交易總量",
		message: "系統消息",
		detail: "詳情",
	},
	assets: {
		recharge: "充值",
		withdraw: "提現",
		type: "类型",
		address: "地址",
		tip: "為了您的資金安全，轉賬成功後，請提交轉賬成功截圖以便審核",
		bank: "银行卡",
	},
	my: {
		about: "平台介紹",
		account_bind: "帳戶綁定",
		approved: "審核通過",
		account_detail: "帳戶明細",
		number: "数量",
		trans_success: "交易成功",
		my_assets: "我的資產",
		edit_nickname: "修改暱稱",
		edit_psd: "修改密碼",
		team: "團隊列表",
		invite: "邀請註冊",
		logout: "退出登錄",
		my_invite_code: "我的邀請碼",
		invite_tip: "複製邀請碼，邀請更多好友加入",
		invite_btn: "申請推廣員",
		team_member: "團隊成員",
		add_month: "本月新增",
		invite2: "邀請",
		personal: "個人資訊",
		avatar: "頭像",
		nickname: "暱稱",
		acount: "賬號",
		invite_code: "推薦碼",
	},
	order: {
		all: "全部",
		paid: "已支付",
		unpaid: "未支付",
		detail: "详情",
		direction: "方向",
		buy: "买入",
		sell: "卖出",
		time: "时间",
		price: "单价",
		number: "数量",
		amount: "金额",
		income: "收益",
	},
	form: {
		place_old_psd: "請輸入舊密碼",
		place_new_psd: "請輸入新密碼",
		place_crmnew_psd: "請再次輸入新密碼",
		place_nickname: "請輸入暱稱",
		place_email_phone: "請輸入郵箱/手機號碼",
		place_login_psd: "請輸入登錄密碼",
		place_email: "請輸入郵箱",
		place_phone: "請輸入手機號碼",
		place_verify_code: "請輸入驗證碼",
		place_confirm_psd: "請確認您的密碼",
		place_invite_code: "請輸入邀請碼",
		place_usdt_number: "請輸入USDT數量",
		buy_price: "買入價格",
		place_buy_price: "請輸入買入價格",
		buy_number: "買入數量",
		place_buy_number: "請輸入買入數量",
		sell_price: "賣出價格",
		place_sell_price: "請輸入賣出價格",
		sell_number: "賣出數量",
		place_sell_number: "請輸入賣出數量",
		nationality: "國籍",
		place_nationality: "請選擇國籍",
		name: "姓名",
		place_name: "請輸入姓名",
		age: "年齡",
		place_age: "請輸入年齡",
		address: "地址",
		place_address: "請輸入地址",
		postal_code: "郵編",
		place_postal_code: "請輸入郵編",
		phone: "電話號碼",
		place_select: "請選擇",
		bank_name: "銀行名稱",
		place_bank_name: "請輸入銀行名稱",
		bank_account: "銀行賬號",
		place_bank_account: "請輸入銀行賬號",
		id_card: "證件照",
		place_id_card: "請上傳證件照",
		place_number: "請輸入數量",
		upload_credentials: "上傳憑證",
		place_upload_credentials: "請上傳憑證",
		place_psd: "請輸入密码",
	},

	city: {
		albania: "阿爾巴尼亞",
		algeria: "阿爾及利亞",
		argentina: "阿根廷",
		armenia: "亞美尼亞",
		australia: "澳大利亞",
		pakistan: "巴基斯坦",
		austria: "奧地利",
		bahrain: "巴林",
		belgium: "比利時",
		bosnia_and_Herzegovina: "波士尼亞和黑塞哥維那",
		brazil: "巴西",
		brunei: "汶萊",
		bulgaria: "保加利亞",
		cambodia: "柬埔寨",
		canada: "加拿大",
		cameroon: "喀麥隆",
		chile: "智利",
		colombia: "哥倫比亞",
		costa_Rica: "哥斯达黎加",
		croatia: "克羅地亞",
		cyprus: "塞浦路斯",
		czech_Republic: "捷克共和國",
		denmark: "丹麥",
		dominican_Republic: "多米尼加共和国",
		egypt: "埃及",
		estonia: "愛沙尼亞",
		ethiopia: "埃塞俄比亚",
		finland: "芬蘭",
		france: "法國",
		georgia: "格魯吉亞",
		germany: "德國",
		ghana: "加納",
		greece: "希臘",
		guyana: "蓋亞那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中國香港",
		hungary: "匈牙利",
		iceland: "冰島",
		ireland: "愛爾蘭",
		italy: "義大利",
		india: "印度",
		indonesia: "印度尼西亞",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈薩克共和國",
		kenya: "肯尼亚",
		korea: "韓國",
		kuwait: "科威特",
		kyrgyzstan: "吉爾吉斯斯坦",
		laos: "老撾",
		latvia: "拉脫維亞",
		lithuania: "立陶宛",
		luxembourg: "盧森堡",
		macao_China: "中國澳門",
		macedonia: "馬其頓",
		malaysia: "馬來西亞",
		malta: "馬爾他",
		mexico: "墨西哥",
		moldova: "摩爾達瓦",
		monaco: "摩納哥",
		mongolia: "蒙古",
		montenegro: "黑山共和國",
		morocco: "摩洛哥",
		myanmar: "緬甸",
		netherlands: "荷蘭",
		new_Zealand: "新西蘭",
		nepal: "尼泊爾",
		nigeria: "尼日利亞",
		norway: "挪威",
		oman: "阿曼",
		palestine: "巴勒斯坦",
		panama: "巴拿馬",
		paraguay: "巴拉圭",
		peru: "秘魯",
		philippines: "菲律賓",
		poland: "波蘭",
		portugal: "葡萄牙",
		puerto_Rico: "波多黎各",
		qatar: "卡塔爾",
		romania: "羅馬尼亞",
		russia: "俄羅斯",
		republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥共和国",
		rwanda: "卢旺达",
		saudi_Arabia: "沙烏地阿拉伯",
		serbia: "塞爾維亞",
		singapore: "新加坡",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亞",
		south_Africa: "南非",
		spain: "西班牙",
		sri_Lanka: "斯里蘭卡",
		sweden: "瑞典",
		switzerland: "瑞士",
		taiwan_China: "中國臺灣",
		tajikistan: "塔吉克共和國",
		tanzania: "坦尚尼亞",
		thailand: "泰國",
		turkey: "土耳其",
		turkmenistan: "土庫曼共和國",
		ukraine: "烏克蘭",
		united_Arab_Emirates: "阿拉伯聯合大公國",
		united_Kingdom: "英國",
		united_States: "美國",
		uzbekistan: "烏茲別克",
		venezuela: "委內瑞拉",
		vietnam: "越南",
		afghanistan: "阿富汗",
		angola: "安哥拉",
		azerbaijan: "阿塞拜疆",
		bangladesh: "孟加拉",
		belarus: "白俄羅斯",
		belize: "貝裡斯",
		benin: "貝南",
		bhutan: "不丹",
		bolivia: "玻利維亞",
		botswana: "博茨瓦纳",
		british_Virgin_Islands: "英屬維京群島",
		burkina_Faso: "伯基納法索",
		burundi: "布隆迪",
		cape_Verde: "佛得角",
		cayman_Islands: "開曼群島",
		central_African_Republic: "中非共和國",
		chad: "乍得",
		comoros: "科摩羅",
		the_Republic_of_the_Congo: "剛果（布）",
		democratic_Republic_of_the_Congo: "剛果（金）",
		djibouti: "吉佈提",
		ecuador: "厄瓜多尔",
		el_Salvador: "薩爾瓦多",
		equatorial_Guinea: "赤道幾內亞",
		eritrea: "厄立特里亚",
		fiji: "斐濟",
		gabon: "加蓬",
		gambia: "岡比亞",
		greenland: "格陵蘭",
		guatemala: "危地马拉",
		guinea: "幾內亞",
		haiti: "海地",
		isle_of_Man: "馬恩岛",
		cote_d_Ivoire: "科特迪瓦",
		jamaica: "牙買加",
		jordan: "約旦",
		lebanon: "黎巴嫩",
		lesotho: "萊索托",
		liberia: "利比里亚",
		libya: "利比亞",
		madagascar: "馬达加斯加",
		malawi: "馬拉维",
		maldives: "馬尔代夫",
		mali: "馬里",
		mauritania: "毛里塔尼亞",
		mauritius: "毛里求斯",
		mozambique: "莫桑比克",
		namibia: "納米比亞",
		nicaragua: "尼加拉瓜",
		republic_of_Niger: "尼日尔",
		north_Korea: "朝鮮",
		reunion: "留尼汪",
		san_Marino: "聖馬力諾",
		senegal: "塞內加爾",
		sierra_Leone: "塞拉利昂",
		somalia: "索馬里",
		sudan: "蘇丹",
		suriname: "蘇里南",
		eswatini: "斯威士兰",
		syria: "敘利亞",
		togo: "多哥",
		tonga: "汤加",
		tunisia: "突尼斯",
		united_States_Virgin_Islands: "美屬維爾京群島",
		uganda: "烏干達",
		uruguay: "烏拉圭",
		vatican: "梵蒂岡",
		yemen: "也门",
		yugoslavia: "南斯拉夫",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
		china: "中國",
	}
};
